.companies-container {
  padding: 1em;
  margin: 0 auto;
}

.companies-container h4 {
  color: #080d37;
  font-size: 1.8em;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 33px;
}

.companies-list-header {
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: row;
  align-items: center;
}

.companies-container .user-intro {
  border-bottom: 1px solid #abb7c0;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom:30px;
}

.companies-container .user-intro p:first-of-type {
  color: #080d37;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.8em;
  text-align: center;
}

/*.companies-container .user-intro p:last-of-type {*/
/*  color: #3b7298;*/
/*  font-size: 15px;*/
/*  font-weight: 500;*/
/*}*/

.companies-prompt {
  text-align: center;
  color: #080d37;
  font-size: 15px;
  font-weight: 500;
  margin: 2em 0;
}

.companies-prompt > strong {
  cursor: pointer;
  color: #61dafb;
}
.companies-prompt > strong:hover {
  color: #546472;
}

.company-data {
  padding:10px 0;
  display: flex;
  border-bottom: 1px solid #ABB7C0;
  align-items: center;
  position: relative;
}
.company-data:last-child{
  border-bottom: 0 none;
}

.company-table .company-name{
  min-width: 260px;
  flex:1 0 30%;
}
.company-data .company-name{
  font-size: 1.1em;
  font-weight: 500;
}
.company-data .last-updated{
  font-size: 0.9em;
  display: flex;
  align-items: center;
}
.company-data .last-updated span{
  padding:0;
  margin-right: 1em;
}
.company-table .data-source{
  flex:1 0 10%;
}
.company-table .last-updated{
  flex:1 0 15%;
}
.company-table .actions{
  flex:1 0 45%;
}
.company-list-wrapper p{
  text-align: center;
  margin: 0;
}
.company-list-wrapper > span{
  text-align: center;
  font-size: 1em;
  display: block;
  margin: 1em 0 2em 0;
}
.company-data .actions a {
  min-width: 125px;
  margin-right: 20px;
  text-align: center;
}
.company-data .actions a:after {
  border-bottom: 0 none;
}

.company-data .settings-icon{
  height: 36px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 20px;
}
.table-data .actions{
  display: flex;
  padding-left: 0;
  padding-right: 1.5em;
  position: relative;
}
.modalOverlay{
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.modalBody{
  padding:2em;
  text-align: center;
}

.simpleModal{
  position: absolute;
  top: 40%;
  left: 50%;
  right: auto;
  min-height: 350px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
  outline: 0 none;
  border: 1px solid #ABB7C0;
  border-radius: 5px;
}
.modalHeader{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D8DFE7;
}
.modalHeader h2{
  flex: 90% 1 1;
  padding: 10px 0 10px 30px;
  font-size: 16px;
  font-weight: 500;
}
.modalHeader .modalCloseButton{
  margin: 10px 20px 10px 10px;
  border: 0 none;
  outline: 0 none;
  background: url("../img/cross-icon.svg") center center no-repeat;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.actions .delete-button{
  cursor: pointer;
}
.actions .delete-button:hover ~ .popoutTooltip{
  display: block;
}
.actions .popoutTooltip {
  top: -1.8em;
  right:0;
  left: auto;
  font-weight: 400;
  font-size: 0.8em;
  color: #fff;
  white-space: nowrap;
  padding: 0.2em;
  border-radius: 3px;
  display: none;
  background: rgb(24 46 66);
  /*position: absolute;*/
}
.hide-row{
  display: none;
  width: 100%;
  height: 70px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: absolute;
}
/*#sync-img:hover{*/
/*  animation:spin 0.5s linear;*/
/*}*/
@keyframes spin { 100% { transform:rotate(180deg); } }