.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-Wrapper {
  margin-top: 60px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 116px);
}
.content-wrapper{
  flex-grow: 1;
  margin-left: 220px;
}
.reportsContainer{
  padding:1.5em 1em;
  margin-top: 80px;
}
.analysisContainer{
  padding:1.5em 1em;
  margin-top: 105px;
}

.App-link {
  color: #61dafb;
}

.Toastify__toast {
  min-height: 45px;
}
.separator-line{
  margin: 1em 0 1em 0;
  border: 0 none;
  border-bottom: 1px solid #ABB7C0;
}
.line-charts-wrapper{
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-around;
  padding-top:1em;
}
.writeups-container{
  line-height: 1.5em;
  display: flex;
}
.writeups-container .green{
color:#2ca02c;
  font-weight: 500;
}
.writeups-container .red{
color: #B70223;
  font-weight: 500;
}

.bs-representation-title {
  font-size: 1em;
  font-weight: bold;
  color: #305a80;
  text-align: center;
  width: 50%;
}
.page-title{
  margin: 0 auto;
  text-align: center;
  position: fixed;
  width: calc(100% - 220px);
  top: 117px;
  background: #ffffff;
  padding: 1em;
  z-index: 1;
  box-shadow: 0px 0px 5px #ccc;
}
.content {
  height: 100%;
  min-height: 500px;
}
.chart-error{
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  padding-left: 40px;
  width: 300px;
  margin: 30px auto;
  display: flex;
  align-items: center;
}
.error-icon{
  background: url('./img/error-icon.svg') no-repeat left center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

