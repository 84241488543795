body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 15px;
  color:#090909;
}
@media screen and (max-width: 1459px){
  body{
    font-size: 13px;
  }
  .company-data .actions a.primary-btn {
    min-width: 100px;
  }
}
@media screen and (max-width: 1190px){
  body{
    font-size: 13px;
  }
  .company-data .actions a.primary-btn {
    min-width: 80px;
  }
  .company-table .company-name {
    min-width: 230px;
    flex: 1 0 27%;
  }
}
@media screen and (max-width: 960px){
  body{
    font-size: 12px;
  }
}
*{
  box-sizing:border-box;
}
h4 {
  color: #080d37;
  font-size: 1.8em;
  font-weight: 300;
  line-height: 33px;
  margin: 10px 0;
}
h5{
  color: #080d37;
  font-size: 1.6em;
  font-weight: 400;
  margin: 10px 0;
}
p {
  font-size: 1.2em;
}

li {
  list-style-type: none;
}

a {
  color: #305A80;
  display: inline-block;
  text-decoration: none;
}
a:after{
  content: '';
  display: block;
  border-bottom: 1px solid #305A80;
}

button:focus {
  outline: none;
}

.react-datepicker-wrapper input {
  outline: 0 none;
  border: 0 none;
  border-bottom: 1px solid #305A80;
  line-height: 1em;
  padding: 6px 10px 5px;
  width: 120px;
}
.primary-btn{
  background: #305A80;
  font-weight: 600;
  color: #fff;
  height: 3.125em;
  font-size: 1em;
  padding: 0 1.5em;
  border-radius: 3px;
  outline: 0 none;
  border: 0 none;
  cursor:pointer;
}
.secondary-btn{
  background:#6A7881;
  font-weight: 600;
  color: #fff;
  height: 3.125em;
  font-size: 1em;
  padding: 0 1.5em;
  border-radius: 3px;
  outline: 0 none;
  border: 0 none;
  cursor:pointer;
}
.secondary-btn:hover{
  background:#ABB7C0;
}
.primary-btn:hover{
  background: #6DB0CC;
}
.primary-btn.small{
  height:2.25em;
  font-size: 1em;
  display: inline-block;
  line-height: 2.2em;
  padding: 0 0.5em;
}
.table {
  margin:0;
  padding:0;
  border: 1px solid #ABB7C0;
  border-radius: 3px;
}
.table span{
  display: inline-block;
}
.table-header{
  background: #6A7881;
  height:3.75em;
  display: flex;
  flex-wrap: nowrap;
}
.table-header span{
  color:#fff;
  font-size: 0.95em;
  font-weight: 600;
  line-height: 3.75em;
  padding-left: 1.5em;
}
.table-data span{
  font-size: 1em;
  padding-left: 1.5em;
}
